import http from "../http-common";

export default class WikiDataServices {
  async getAll(pageNumber, pageSize) {
    return await http.get(
      `v1/wiki?PageNumber=${pageNumber}&PageSize=${pageSize}`
    );
  }

  async getAllInactive(pageNumber, pageSize) {
    return await http.get(`v1/wiki/inactive?PageNumber=${pageNumber}&PageSize=${pageSize}`);
  }

  async getAllWithOutUser(pageNumber, pageSize, companyId) {
    return await http.get(
      `v1/wiki/just-name?PageNumber=${pageNumber}&PageSize=${pageSize}&companyId=${companyId}`
    );
  }

  async getForTitle(title) {
    return await http.get(`v1/wiki/for-title?title=${title}`);
  }

  async getForCategory(categoryId, pageNumber, pageSize) {
    return await http.get(
      `v1/wiki/for-category/${categoryId}?PageNumber=${pageNumber}&PageSize=${pageSize}`
    );
  }

  async add(data, FunctionLoading) {
    return await http.post(`v1/wiki`, data, {
      onUploadProgress: (event) => {
        let progress = Math.round((event.loaded * 100) / event.total);
        FunctionLoading(progress);
      },
    });
  }

  async update(wikiId, data, FunctionLoading) {
    return await http.put(`v1/wiki/${wikiId}`, data, {
      onUploadProgress: (event) => {
        let progress = Math.round((event.loaded * 100) / event.total);
        FunctionLoading(progress);
      },
    });
  }

  async get(wikiId) {
    return await http.get(`v1/wiki/${wikiId}`);
  }

  async read(wikiId) {
    return await http.post(`v1/wiki/${wikiId}/read`);
  }

  async evaluation(wikiId, evaluation) {
    return await http.post(`v1/wiki/${wikiId}/evaluation/${evaluation}`);
  }

  async finish(wikiId) {
    return await http.post(`v1/wiki/${wikiId}/finish-read`);
  }

  async getAllCategories(companyId) {
    return await http.get(`v1/wiki/category?companyId=${companyId}`);
  }

  async updateCategories(categoryId, data) {
    return http.put(
      `v1/wiki/category-update?wikiCategoryId=${categoryId}`,
      data
    );
  }

  async deleteWiki(wikiId) {
    return await http.delete(`v1/wiki/${wikiId}/disabled`);
  }

  async addCategories(data) {
    return http.post(`v1/wiki/category-add`, data);
  }

  async removeCategories(categoryId) {
    return await http.delete(
      `v1/wiki/wiki-category-disabled?categoryId=${categoryId}`
    );
  }

  async wikiHistory(data) {
    return await http.post(`v1/wiki/history-wiki`, data);
  }
}
